import Tooltip from "../Tooltip";
import styles from "./Label.module.scss";

interface Props {
  text: string;
  required?: boolean;
  tooltip?: string;
  lockedTooltip?: string;
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  [x: string]: any;
}

const Label = ({
  text,
  required,
  children,
  tooltip,
  lockedTooltip,
  className,
  style,
  ...rest
}: Props) => {
  return (
    <>
      <label
        className={`${className ?? ""} ${required ? styles["required"] : ""}`}
        style={style}
        {...rest}
      >
        {text}
        {tooltip && (
          <Tooltip content={tooltip}>
            <i
              className="fas fa-info-circle ms-1 text-muted"
              style={{ fontSize: "14px", width: "14px", height: "14px" }}
            />
          </Tooltip>
        )}
        {lockedTooltip && (
          <Tooltip content={lockedTooltip}>
            <i
              className="fas fa-lock ms-2"
              style={{ fontSize: "14px", width: "14px", height: "14px" }}
            />
          </Tooltip>
        )}
      </label>
      {children}
    </>
  );
};

export default Label;
