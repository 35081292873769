import { Spinner } from "react-bootstrap";

import styles from "./Button.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: "button" | "submit" | "reset";
  color?: string;
  isOutline?: boolean;
  isBlock?: boolean;
  isLoading?: boolean;
  className?: string;
}

const Button = ({
  type,
  color,
  isOutline,
  isBlock,
  children,
  className,
  isLoading,
  style,
  disabled,
  ...rest
}: Props) => {
  const button = (
    <button
      type={type}
      className={`${styles["button-main"]} ${
        isOutline ? styles["button-default-outline"] : styles["button-default"]
      } ${className ? className : ""}`}
      style={{
        ...(color && !disabled
          ? {
              backgroundColor: isOutline ? "#fff" : color,
              color: isOutline ? color : "#fff",
              border: `1px solid ${color}`,
            }
          : {}),
        ...(style ?? {}),
      }}
      onMouseEnter={(e) => {
        if (color && !disabled) {
          if (isOutline) {
            e.currentTarget.style.backgroundColor = color;
            e.currentTarget.style.color = "#fff";
          } else {
            e.currentTarget.style.backgroundColor = "#fff";
            e.currentTarget.style.color = color;
          }
        }
      }}
      onMouseLeave={(e) => {
        if (color && !disabled) {
          if (isOutline) {
            e.currentTarget.style.backgroundColor = "#fff";
            e.currentTarget.style.color = color;
          } else {
            e.currentTarget.style.backgroundColor = color;
            e.currentTarget.style.color = "#fff";
          }
        }
      }}
      disabled={disabled}
      {...rest}
    >
      {isLoading ? <Spinner animation="border" size="sm" /> : children}
    </button>
  );

  if (isBlock) {
    return <div className="d-grid gap-2">{button}</div>;
  }

  return button;
};

export default Button;
