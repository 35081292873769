import React, { useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import type { PDFDocumentProxy } from "pdfjs-dist";

import styles from "./Pdf.module.scss";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface Props {
  pdfDocument: string;
  useFullPage?: boolean;
  buttonColor?: string;
}

const Pdf: React.FC<Props> = ({ pdfDocument, useFullPage, buttonColor }) => {
  const { t } = useTranslation(["external"]);

  const [maxPages, setMaxPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const onPageSwitch = (pageToAdd: number) => {
    const nextPage = pageNumber + pageToAdd;

    if (nextPage < maxPages && nextPage >= 0) {
      setPageNumber(nextPage);
    }
  };

  const onDocumentLoadSuccess = ({
    numPages: nextNumPages,
  }: PDFDocumentProxy) => {
    setMaxPages(nextNumPages);
  };

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Document
          file={pdfDocument}
          onLoadSuccess={onDocumentLoadSuccess}
          options={{
            cMapUrl: "cmaps/",
            standardFontDataUrl: "standard_fonts/",
          }}
          loading={<Loading />}
        >
          <Page
            pageNumber={pageNumber + 1}
            className={`${styles.page} shadow-lg mb-2 mt-1`}
            scale={1.5}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </Document>
      </div>
      {maxPages > 1 && (
        <div className="d-flex justify-content-center mt-3">
          <div
            className={`bottom-0 mb-3 ${
              useFullPage ? "position-fixed" : "position-relative"
            }`}
          >
            <Button
              color={buttonColor}
              onClick={() => onPageSwitch(-1)}
              disabled={pageNumber === 0}
              data-test="digital-menu-prev-page"
            >
              <i className="fa fa-arrow-left" />
            </Button>
            <span className="px-4">
              {t("external:digitalmenu.pageOfMaxPages", {
                page: pageNumber + 1,
                max: maxPages,
              })}
            </span>
            <Button
              color={buttonColor}
              onClick={() => onPageSwitch(1)}
              disabled={pageNumber + 1 === maxPages}
              data-test="digital-menu-next-page"
            >
              <i className="fa fa-arrow-right" />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Pdf;
