import { useTranslation } from "react-i18next";

export interface CardProps {
  title?: string | null;
  subtitle?: string | null;
  icon?: string;
  action?: React.ReactNode;
  forAdmin?: boolean;
  forSetup?: boolean;
  forDemo?: boolean;
  forCurrent?: boolean;
  dangerous?: boolean;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  className?: string;
  disablePadding?: boolean;
  disableBackground?: boolean;
  colorSubtitle?: string;
  [key: string]: any;
}

const Card = ({
  title,
  subtitle,
  icon,
  action,
  forAdmin,
  forSetup,
  forDemo,
  forCurrent,
  dangerous,
  children,
  style,
  innerStyle,
  className,
  disablePadding,
  disableBackground,
  colorSubtitle,
  ...rest
}: CardProps) => {
  const { t } = useTranslation(["general"]);

  return (
    <div
      className={`${disableBackground ? "" : "bg-white"} shadow ${
        className ?? ""
      }`}
      style={{ borderRadius: "6px", position: "relative", ...style }}
      {...rest}
    >
      {forAdmin && (
        <div style={{ position: "absolute", right: "15px", top: "10px" }}>
          <span className="badge bg-danger">
            {t("general:tags.admin") as string}
          </span>
        </div>
      )}

      {forSetup && (
        <div style={{ position: "absolute", right: "15px", top: "10px" }}>
          <span className="badge bg-warning text-dark">
            {t("general:tags.setup") as string}
          </span>
        </div>
      )}

      {forDemo && (
        <div style={{ position: "absolute", right: "15px", top: "10px" }}>
          <span
            className="badge text-secondary"
            style={{ backgroundColor: "#b2e3ed" }}
          >
            {t("general:tags.demo") as string}
          </span>
        </div>
      )}

      {forCurrent && (
        <div style={{ position: "absolute", right: "15px", top: "10px" }}>
          <span
            className="badge text-dark"
            style={{ backgroundColor: "#b2e3ed" }}
          >
            {t("general:tags.current") as string}
          </span>
        </div>
      )}

      {dangerous && (
        <div style={{ position: "absolute", right: "15px", top: "10px" }}>
          <span className="badge bg-danger">
            {t("general:tags.dangerous") as string}
          </span>
        </div>
      )}

      {title && (
        <div className="border-bottom px-4 py-2 d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {icon && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginRight: "20px", color: "#a6aaad" }}
              >
                <i className={icon} style={{ fontSize: "1.4rem" }} />
              </div>
            )}

            <div className="d-flex flex-column justify-content-center">
              <h5 style={{ fontWeight: 600, marginTop: "7px" }}>{title}</h5>
              {subtitle && (
                <div
                  style={{ marginTop: "-10px", marginLeft: "1px" }}
                  className={colorSubtitle ? "" : "text-muted"}
                >
                  <small style={colorSubtitle ? { color: colorSubtitle } : {}}>
                    {subtitle}
                  </small>
                </div>
              )}
            </div>
          </div>

          {action && (
            <div className="d-flex justify-content-end align-items-center">
              {action}
            </div>
          )}
        </div>
      )}

      {!title && action && (
        <div style={{ position: "absolute", top: "5px", right: "10px" }}>
          {action}
        </div>
      )}

      <div
        className={`${!disablePadding ? "p-3 ps-4 pe-4" : ""}`}
        style={innerStyle}
      >
        {children}
      </div>
    </div>
  );
};

export default Card;
