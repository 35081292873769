import { useTranslation } from "react-i18next";
import { availableLanguages } from "../../../i18n";

interface Props {
  onChange?: (language: string) => void;
  changeLanguageOfSystem?: boolean;
  initialLanguage?: string;
  hideFlag?: boolean;
  [key: string]: any;
}

const LanguageSelector = ({
  onChange,
  initialLanguage,
  hideFlag,
  changeLanguageOfSystem = true,
  ...rest
}: Props) => {
  const { i18n } = useTranslation();
  const currentLanguage =
    availableLanguages.find(
      (language) =>
        language.code ===
        (initialLanguage
          ? initialLanguage
          : i18n.language.slice(0, 2).toLocaleLowerCase())
    ) || availableLanguages[0];

  const onLanguageSelectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    changeLanguageOfSystem && i18n.changeLanguage(event.target.value);
    onChange && onChange(event.target.value);
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center pe-1"
      {...rest}
    >
      {!hideFlag && <div className="ps-3 pe-2">{currentLanguage.flag}</div>}
      <select
        onChange={onLanguageSelectionChange}
        className="form-select form-select-sm text-center text-muted"
        style={{
          width: "60px",
          border: "none",
          outline: "none",
          backgroundColor: "transparent",
        }}
        value={currentLanguage.code}
      >
        {availableLanguages.map((language) => (
          <option key={language.code} value={language.code}>
            {language.code.toUpperCase()}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
