interface ConfigOptions {
  mode: "development" | "staging" | "production";
  baseUrl: string;
  apiBaseUrl: string;
  buildNumber: string | undefined;
}

export const Config: ConfigOptions = {
  mode: import.meta.env.VITE_APP_MODE_TYPE as ConfigOptions["mode"],
  baseUrl: import.meta.env.VITE_APP_BASE_URL,
  apiBaseUrl: import.meta.env.VITE_APP_API_BASE_URL,
  buildNumber: import.meta.env.VITE_APP_BUILD_NUMBER,
};
