import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { AccountRole } from "../../@types/account";
import { Business, BusinessUser } from "../../@types/business";
import { ModuleType } from "../Modules";
import {
  getLocalstorageItem,
  removeLocalstorageItem,
  setLocalstorageItem,
} from "../services/localstorage-service";
import useFetch from "../hooks/useFetch";

interface JwtToken {
  sub: string;
  unique_name: string;
  Id: string;
  Role: string;
  exp: number;
}

interface AuthContextType {
  token: string;
  id: string;
  role: AccountRole | null;
  isLoggedIn: boolean;
  login: (token: string, business: Business) => void;
  logout: () => void;
  business: Business;
  setBusiness: (business: Business) => void;
  businessList: Business[];
  setBusinessList: (businessList: Business[]) => void;
  hasPermission: (permission: string) => boolean;
  hasModule: (module: ModuleType) => boolean;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

export const AuthContextProvider = ({ children }: { children: any }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["auth_token"]);

  const { fetchData: getAllBusinessesForUser } = useFetch(
    "GET",
    ["business"],
    false
  );

  const [token, setToken] = useState<string | null>(
    cookies.auth_token ?? getLocalstorageItem("token")
  );
  const [business, setBusiness] = useState<Business | null>(
    JSON.parse(getLocalstorageItem("business")!)
  );
  const [businessList, setBusinessList] = useState<Business[]>(
    business ? [business] : []
  );

  useEffect(() => {
    if (token) {
      getAllBusinessesForUser({}, {}, token)
        .then((response) => response.json())
        .then((response: Business[] | null) => {
          const businesses = response?.filter((b) => b && b.isEnabled);

          if (businesses && businesses.length > 0) {
            setBusinessList(businesses);
            !business && setBusinessHandler(businesses[0]);

            const currentBusinessToUpdate = businesses.find(
              (b) => b.id === business?.id
            );
            if (currentBusinessToUpdate) {
              setBusinessHandler(currentBusinessToUpdate);
            }
          }
        });
    }
  }, []);

  const getUserId = () => token && jwt_decode<JwtToken>(token).Id;
  const getUserRole = () =>
    token ? (jwt_decode<JwtToken>(token).Role as AccountRole) : null;

  const isLoggedIn = () => {
    return (
      !!token &&
      !!business &&
      new Date(jwt_decode<JwtToken>(token).exp * 1000) > new Date()
    );
  };

  const loginHandler = (token: string, business: Business) => {
    setToken(token);
    setBusiness(business);

    setCookie("auth_token", token, {
      secure: true,
      sameSite: "strict",
      expires: new Date(jwt_decode<JwtToken>(token).exp * 1000),
    });
  };

  const logoutHandler = () => {
    setToken(null);
    setBusiness(null);

    removeCookie("auth_token");

    // Localstorage is used for authentication in mobile app
    removeLocalstorageItem("token");
    removeLocalstorageItem("business");
  };

  const setBusinessHandler = (business: Business) => {
    setBusiness(business);
    setLocalstorageItem("business", JSON.stringify(business));
  };

  const hasPermissionHandler = (permission: string) => {
    const businessUser = business?.users.find(
      (u: BusinessUser) => u.userId === getUserId()
    );
    return !!(businessUser && !!businessUser[permission as keyof BusinessUser]);
  };

  const hasModuleHandler = (module: ModuleType) => {
    return !!(business && business.enabledModules.includes(module));
  };

  const contextValue: AuthContextType = {
    token: token!,
    id: getUserId()!,
    role: getUserRole()!,
    isLoggedIn: isLoggedIn(),
    login: loginHandler,
    logout: logoutHandler,
    business: business!,
    setBusiness: setBusinessHandler,
    businessList: businessList,
    setBusinessList: setBusinessList,
    hasPermission: hasPermissionHandler,
    hasModule: hasModuleHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
