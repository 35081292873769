import { useTranslation } from "react-i18next";
import ErrorText from "./ErrorText";
import { FieldMetaState } from "react-final-form";

interface Props {
  options: {
    title: string;
    value: string;
  }[];
  meta?: FieldMetaState<any>;
  [x: string]: any;
}

const Select = ({ options, meta, ...rest }: Props) => {
  const { t } = useTranslation(["general"]);

  const isNotValid = meta && meta.error && meta.error[0] && meta.touched;

  return (
    <>
      <select
        className={`form-select mb-2 my-1 ${isNotValid ? "is-invalid" : ""}`}
        {...rest}
      >
        {options &&
          options.map((option) => (
            <option key={option.title} value={option.value}>
              {option.title}
            </option>
          ))}
      </select>

      {isNotValid && (
        <ErrorText>
          {t(`general:fields.validators.${meta.error[0]}`, {
            value: meta.error[1],
          })}
        </ErrorText>
      )}
    </>
  );
};

export default Select;
