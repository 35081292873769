import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";

interface TooltipProps {
  children: any;
  content: React.ReactNode;
  placement?: "top" | "right" | "bottom" | "left";
}

const Tooltip = ({
  children,
  placement = "top",
  content,
  ...rest
}: TooltipProps) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<BootstrapTooltip>{content}</BootstrapTooltip>}
      {...rest}
    >
      {children}
    </OverlayTrigger>
  );
};

export default Tooltip;
