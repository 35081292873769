class Validators {
  static compose =
    (...validators: any) =>
    (value: any) =>
      validators.reduce(
        (error: any, validator: any) => error || validator(value),
        undefined
      );

  static required = () => {
    return (input: any) => {
      return input && input.length > 0 ? undefined : ["required", null];
    };
  };

  static maxLength = (amount: any) => {
    return (input: any) => {
      return !input || (input && input.length <= amount)
        ? undefined
        : ["maxLength", amount];
    };
  };

  static minLength = (amount: any) => {
    return (input: any) => {
      return input && input.length >= amount
        ? undefined
        : ["minLength", amount];
    };
  };

  static maxValue = (amount: any) => {
    return (input: any) => {
      return input && input <= amount ? undefined : ["maxValue", amount];
    };
  };

  static minValue = (amount: any) => {
    return (input: any) => {
      return input && input >= amount ? undefined : ["minValue", amount];
    };
  };

  static number = () => {
    return (input: any) => {
      const re = /^[0-9]*$/;
      return !input || (input && re.test(input)) ? undefined : ["number", null];
    };
  };

  static email = () => {
    return (input: any) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !input || (input && re.test(input)) ? undefined : ["email", null];
    };
  };

  static telephone = () => {
    return (input: any) => {
      const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
      return !input || (input && re.test(input))
        ? undefined
        : ["telephone", null];
    };
  };

  static emailNoPublicDomain = () => {
    return (input: any) => {
      return input &&
        !input.toLowerCase().includes("gmail") &&
        !input.toLowerCase().includes("hotmail") &&
        !input.toLowerCase().includes("yahoo")
        ? undefined
        : ["emailNoPublicDomain", null];
    };
  };

  static date = () => {
    return (input: any) => {
      const re =
        /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;
      return !input || (input && re.test(input)) ? undefined : ["date", null];
    };
  };

  static colorCode = () => {
    return (input: any) => {
      const re = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
      return !input || (input && re.test(input))
        ? undefined
        : ["colorCode", null];
    };
  };
}

export default Validators;
