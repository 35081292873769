import { LeapFrog } from "@uiball/loaders";

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LeapFrog size={40} speed={2.5} color="black" />
    </div>
  );
};

export default Loading;
