import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { Be, Fr, Us } from "react-flags-select";

import { Config } from "./Config";

export const availableLanguages = [
  {
    code: "nl",
    name: "Nederlands",
    flag: <Be />,
  },
  {
    code: "en",
    name: "English",
    flag: <Us />,
  },
  {
    code: "fr",
    name: "Français",
    flag: <Fr />,
  },
];

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs: availableLanguages.map((l) => l.code),
    fallbackLng: "nl",
    debug: false,
    ns: "external",
    defaultNS: "external",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: () => {
        if (Config.mode === "development") {
          return "/locales/{{lng}}/{{ns}}.json";
        }

        return `${Config.baseUrl}/locales/{{lng}}/{{ns}}.json`;
      },
    },
  });

export default i18n;
