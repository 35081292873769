import { useTranslation } from "react-i18next";
import ErrorText from "./ErrorText";
import { FieldMetaState } from "react-final-form";

interface Props {
  meta?: FieldMetaState<any>;
  isTextArea?: boolean;
  disableSpacing?: boolean;
  disableErrorText?: boolean;
  [x: string]: any;
}

const Input = ({
  meta,
  isTextArea,
  disableSpacing,
  disableErrorText,
  ...rest
}: Props) => {
  const { t } = useTranslation(["general"]);

  const isNotValid = meta && meta.error && meta.error[0] && meta.touched;

  const renderInput = () => {
    if (!isTextArea) {
      return (
        <input
          className={`form-control ${isNotValid ? "is-invalid" : ""}`}
          {...rest}
        />
      );
    }

    return (
      <textarea
        className={`form-control ${isNotValid ? "is-invalid" : ""}`}
        {...rest}
      />
    );
  };

  return (
    <div className={disableSpacing ? "" : "my-1"}>
      {renderInput()}

      {isNotValid && !disableErrorText && (
        <ErrorText>
          {t(`general:fields.validators.${meta.error[0]}`, {
            value: meta.error[1],
          })}
        </ErrorText>
      )}
    </div>
  );
};

export default Input;
