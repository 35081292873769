import { Suspense, useEffect } from "react";
import WebFont from "webfontloader";
import "./src/i18n";
import "./src/index.scss";
import "react-day-picker/dist/style.css";
import { Loading } from "./src/components/layout/ui";
import { Config } from "./src/Config";

const addLinkElement = (href: string) => {
  const link = document.createElement("link");
  link.href = href;
  link.rel = "stylesheet";
  document.head.appendChild(link);
};

interface Props {
  autoInjectCss?: boolean;
  children: React.ReactNode;
}

const Container = ({ autoInjectCss, children }: Props) => {
  useEffect(() => {
    // Load Google Fonts
    WebFont.load({
      google: {
        families: [
          "Poppins:300",
          "Poppins:400",
          "Poppins:500",
          "Poppins:600",
          "Poppins:700",
        ],
      },
    });

    // Font Awesome hack
    addLinkElement(
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
    );

    if (autoInjectCss) {
      addLinkElement(`${Config.baseUrl}/Container-${Config.buildNumber}.css`);
    }
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <div style={{ fontFamily: "Poppins" }}>{children}</div>
    </Suspense>
  );
};

export default Container;
