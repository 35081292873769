import styles from "./IconButton.module.scss";

interface Props {
  icon: string;
  className?: string;
  onClick?: () => void;
  [x: string]: any;
}

const IconButton = ({ icon, className, onClick, ...rest }: Props) => {
  return (
    <i
      className={`${styles["icon-button"]} ${icon} ${className}`}
      onClick={onClick}
      {...rest}
    />
  );
};

export default IconButton;
