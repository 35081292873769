export enum Colors {
  Default = "#7367f0",
  Default_Dark = "#130C60",
  Danger = "#DC3545",
  Warning = "#FFC107",
  Success = "#198754",
  Info = "#6C757D",
  White = "#FFFFFF",
  Black = "#141923",
  Green = "#28A745",
  DarkBlue = "#434BB1",
  DarkCyan = "#049588",
  DarkOrange = "#FF6B00",
  DarkMint = "#00766B",
  DarkGray = "#6C757D",
  LightBlue = "#E8E6F4",
  LightRed = "#fae3e5",
  LightGray = "#F8F9FA",
  LightGreen = "#7ae6b2",
  LightMint = "#E6F5F4",
  LightOrange = "#FFECDE",
  LightYellow = "#FFD166",
}
