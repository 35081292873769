interface Props {
  value: number;
  currency?: string;
  useDecimals?: boolean;
}

export const formatMoney = (
  value: number,
  currency = "EUR",
  useDecimals = true
) => {
  const formatter = new Intl.NumberFormat("nl-BE", {
    style: "currency",
    currency,
    minimumFractionDigits: useDecimals ? 2 : 0,
  });

  return formatter.format(value);
};

const MoneyFormat = ({
  value,
  currency = "EUR",
  useDecimals = true,
}: Props) => {
  const formatter = new Intl.NumberFormat("nl-BE", {
    style: "currency",
    currency,
    minimumFractionDigits: useDecimals ? 2 : 0,
  });

  return <>{formatter.format(value)}</>;
};

export default MoneyFormat;
