import { useContext, useState } from "react";
import { Config } from "../Config";
import AuthContext from "../store/auth-context";

const useFetch = (
  method: "POST" | "GET" | "DELETE" | "PUT",
  requestArray: string[],
  addAuthorizationHeader = true
) => {
  const authContext = useContext(AuthContext);

  const [loading, setIsLoading] = useState(false);

  const fetchData = async (
    params?: any,
    body?: any,
    customAuthorizationToken: string | null = null,
    useContentType = true
  ): Promise<Response> => {
    setIsLoading(true);

    const parametersToAdd = params ? params : body;
    const url = `${Config.apiBaseUrl}/${requestArray.join(
      "/"
    )}?${new URLSearchParams(
      addAuthorizationHeader
        ? { businessId: authContext?.business?.id, ...parametersToAdd }
        : parametersToAdd
    ).toString()}`;

    try {
      const response = await fetch(url, {
        method: method || "GET",
        headers: {
          accept: "application/json",
          ...(useContentType && {
            "Content-Type": "application/json",
          }),
          ...(addAuthorizationHeader && {
            Authorization: `Bearer ${authContext?.token}`,
          }),
          ...(customAuthorizationToken
            ? {
                Authorization: `Bearer ${customAuthorizationToken}`,
              }
            : {}),
        },
        ...(method !== "GET" &&
          method !== "DELETE" &&
          body && { body: useContentType ? JSON.stringify(body) : body }),
      });

      setIsLoading(false);

      return response;
    } catch (error: any) {
      setIsLoading(false);

      return new Promise(() => {
        return {
          status: 500,
          statusText: error.message,
        };
      });
    }
  };

  return { fetchData, loading };
};

export default useFetch;
